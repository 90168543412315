import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		// Scroll the window or a specific container to the top
		const scrollableElement = document.querySelector(".main_main") || window;
		console.log(scrollableElement);
		scrollableElement.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

export default ScrollToTop;
