import { requestGet } from "../utils/dva16";

import {
	NInsightsFeed,
	EGetOne,
	EGetDetail,
	RSetState,
	EGetTop,
	EGet,
	EGetTopFive
} from "./contants";

const InsightsFeed = {
	namespace: NInsightsFeed,
	state: {
		InsightsFeedList: null,
		loading: false,
		EsportsList: null,
		InsightDetails: null,
		topNewList: null,
		topFiveList: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("insightFeed", payload);
			reducer(RSetState, { InsightsFeedList: res, loading: false });
			callback && callback(res);
		},
		async [EGetTop]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("insightFeed", payload);
			reducer(RSetState, { topNewList: res });
		},
		async [EGetTopFive]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("insightFeed/topFive/inv", payload);
			reducer(RSetState, { topFiveList: res });
		},

		async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("insightFeed", payload);
			reducer(RSetState, { EsportsList: res, loading: false });
		},

		async [EGetDetail](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			reducer(RSetState, { loading: true });
			let res = await requestGet(`insightFeed/${payload?.id}`, payload);
			reducer(RSetState, { InsightDetails: res, loading: false });
		},
	},
};
export default InsightsFeed;
