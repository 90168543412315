import menu1 from "./menu/menu1.png";
import menu2 from "./menu/menu2.png";
import menu3 from "./menu/menu5.png";
import menu4 from "./menu/menu4.png";
import menu5 from "./menu/menu3.png";
import menu_exclusives from "./menu/menu_exclusives.png";
import menuGame from "./menu/menu_game.png";
import logoNiko from "./icon/logo_niko.png";
import logoNikoBeta from "./icon/logo_niko_beta.png";

import game from "./images/game.png";
import lock from "./images/lock.png";
import top from "./images/top.png";

import ico_diqiu from "./images/home/ico_diqiu.png";
import ico_shencha from "./images/home/ico_shencha.png";
import ico_tongji from "./images/home/ico_tongji.png";
import ico_yewu from "./images/home/ico_yewu.png";
import arrow_left from "./icon/arrow_left_bule.png";
import arrow_right from "./icon/arrow_right_white.png";
import icon_article from "./icon/icon_article.png";
import icon_share from "./icon/icon_share.png";
import icon_pdf from "./icon/icon_pdf.png";

import home_icon1 from "./images/home/home_icon1.png";
import home_icon2 from "./images/home/home_icon2.png";
import home_icon3 from "./images/home/home_icon3.png";
import home_icon4 from "./images/home/home_icon4.png";
import home_icon5 from "./images/home/home_icon5.png";

import footer_icon from "./new_style/images/logo-niko.png";

import home_market from "./new_style/images/home-page/illustration_market-model.png";
import home_news from "./new_style/images/home-page/illustration_news-articles.png";
import home_games from "./new_style/images/home-page/illustration_games-organizations.png";
import home_exclusives from "./new_style/images/home-page/illustration_nicoiq-exclusives.png";
import home_esports from "./new_style/images/home-page/illustration_esports.png";
import home_china_data from "./new_style/images/home-page/illustration_china-data.png";
import home_financial_data from "./new_style/images/home-page/illustration_financial-data_inactive.png";
import home_gamer_insights from "./new_style/images/home-page/illustration_gamer-insights_inactive.png";

import menu_games from "./new_style/menu_icons/icon_games.png";
import menu_market from "./new_style/menu_icons/icon_market-model.png";
import menu_news from "./new_style/menu_icons/icon_articles.png";
import menu_esport from "./new_style/menu_icons/icon_esport.png";
import menu_icafe from "./new_style/menu_icons/icafe.svg";
import menu_exclusive from "./new_style/menu_icons/exclusive.svg";
import menu_gamer_insight from "./new_style/menu_icons/users.svg";
import right_arrow from "./new_style/icons/right-arrow.svg";
import right_arrow_blue from "./new_style/icons/right-arrow-blue.svg";
import globe_icon from "./new_style/icons/globe.svg";
import map_icon from "./new_style/icons/icon_map.svg";
import folder_icon from "./new_style/icons/icon_folder.svg";
import globe_gray_icon from "./new_style/icons/globe_gray.svg";
import next_icon from "./new_style/icons/next_icon.svg";
import prev_icon from "./new_style/icons/prev_icon.svg";
import copy_icon from "./new_style/icons/copy.svg";

import external_link_icon from "./new_style/icons/external_link_icon.svg";

const imgSource = {
	logoNiko,
	logoNikoBeta,
	menu1,
	menu2,
	menu3,
	menu4,
	menu5,
	menu_exclusives,
	menuGame,
	game,
	lock,
	top,
	ico_diqiu,
	ico_shencha,
	ico_tongji,
	ico_yewu,
	arrow_left,
	arrow_right,
	icon_article,
	icon_share,
	icon_pdf,
	home_icon1,
	home_icon2,
	home_icon3,
	home_icon4,
	home_icon5,
	footer_icon,
	home_market,
	home_news,
	home_games,
	home_exclusives,
	home_esports,
	home_china_data,
	home_financial_data,
	home_gamer_insights,
	menu_games,
	menu_market,
	menu_news,
	menu_esport,
	menu_icafe,
	menu_exclusive,
	right_arrow,
	right_arrow_blue,
	globe_icon,
	map_icon,
	folder_icon,
	globe_gray_icon,
	next_icon,
	prev_icon,
	copy_icon,
	external_link_icon,
	menu_gamer_insight,
};

export default imgSource;
