import React, { useState, useEffect } from "react";
import moment from "moment";
import { effect, useStore } from "../../../utils/dva16";
import E from "../../../models/E";
import "../Profile/index.less";
import "./index.less";
import formatDate from "@/utils/dateFormater";
import Defualt from "@/assets/images/user_head_pic.png";
import ChangePassword from "../../../layout/components/ChangePassword";
import iconArrow from "@/assets/icon/arrow-down_mobile-table.png";
import reverseArrow from "@/assets/icon/arrow-down_mobile-table - reverse.png";
import Checked from "@/assets/new_style/profile/icon_check-green@2x.png";
import UnChecked from "@/assets/new_style/profile/icon_x@2x.png";
import {
	Typography,
	Table,
	Row,
	Col,
	Button,
	Tabs,
	Empty,
	Collapse,
} from "antd";
// import { Tabs, Empty, Button } from "antd";
const { TabPane } = Tabs;
import SubScription from "./component/SubScription";
import CurrentSubscribe from "./component/CurrentSubscribe";
import {
	EGet,
	NLogin,
	EPostPassWordEdit,
	EGetCurrentSubscription,
} from "../../../models/contants";
import CustomEmpty from "@/utils/CustomEmpty";
import { useMediaQuery } from "react-responsive";
import { img } from "@/assets";
import { registerCoordinate } from "bizcharts/lib/plots/core/dependents";
import { noActiveSubscription } from "../../../utils/utils";
const { Title, Text, Link } = Typography;

export default (props) => {
	const { Panel } = Collapse;
	const { userInfo, currentSubscribe } = useStore(NLogin);
	const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
	const [visible, setVisible] = useState(false);
	const [editItem, setEditItem] = useState(null);
	const [subscribe, setSubscribe] = useState(null);
	const [show, setShow] = useState(false);
	const [showHistory, setShowHistory] = useState(false);
	useEffect(() => {
		effect(NLogin, EGet, {});
		setShowHistory(false);
	}, []);
	const columns = [
		{
			title: "Subscribe Date",
			dataIndex: "subscribeDate",
			key: "subscribeDate",
			width: 100,
			align: "center",
			render: (text, value, index) => (
				<>{text ? `${formatDate(new Date(text))}` : "-"}</>
			),
		},
		{
			title: "Expired data",
			dataIndex: "expiredDate",
			key: "expiredDate",
			width: 100,
			align: "center",
			render: (text, value, index) => (
				<>{text ? `${formatDate(new Date(text))}` : "-"}</>
			),
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			width: 100,
			align: "center",
			render: (text, value, index) => (
				<>
					{text
						?.map((item, index) => {
							return item ? item : "-";
						})
						?.join(", ")}
				</>
			),
		},
		{
			title: "Subscription",
			dataIndex: "Subscription",
			key: "Subscription",
			width: 100,
			align: "center",
			render: (text, record, index) => (
				<div>
					<Link
						onClick={() => {
							onshow(record);
						}}
					>
						View
					</Link>
				</div>
			),
		},
	];
	const onCreate = (userInfo) => {
		setVisible(true);
		setEditItem(userInfo);
	};
	const onshow = (record) => {
		setShow(true);
		setSubscribe(record);
	};
	const [activeTab, setActiveTab] = useState("1");
	const onChange = (key) => {
		setActiveTab(key);
	};
	const [activeKey, setActiveKey] = useState([]);

	const handleCollapseChange = (key) => {
		setActiveKey(key);
	};
	const date = new Date(userInfo && userInfo?.createdAt);
	const formattedDate = formatDate(date);

	const modulesArray =
		userInfo?.subscriptionInfo[0] &&
		Object.entries(userInfo?.subscriptionInfo[0]?.module)?.map(
			([key, value]) => ({
				[key]: value,
			})
		);

	return (
		<div className="GridBox wd100">
			<div className="user_Info_Box">
				<div className="user_Lft_Label">
					<div className="u_Lft_Head_Info">
						<img src={Defualt} style={{ width: "75px" }} />

						<div className="u_Lft_UserBox_info">
							<div className="u_Lft_User_Name">{userInfo?.username}</div>
						</div>
						<Button
							type="primary"
							className="history"
							onClick={() => {
								onCreate(userInfo);
							}}
						>
							Change Password
						</Button>
					</div>
					<div className="u_Lft_Lst_Info">
						<div className="label" style={{ marginTop: "10px" }}>
							Email
						</div>
						<div className="text">{userInfo?.email}</div>
						<div className="label">User Type</div>
						<div className="text">{userInfo?.type}</div>
						<div className="label">Joined Since</div>
						<div className="text" style={{ marginBottom: "10px" }}>
							{formattedDate}
						</div>
					</div>
				</div>
				<div className="user_Rgt_Label">
					<Tabs
						onChange={onChange}
						activeKey={activeTab}
						type="card"
						className="custom-tabs profileTabs"
					>
						{
							<TabPane tab="Current Subscription" key="1">
								{currentSubscribe ? (
									<CurrentSubscribe />
								) : (
									noActiveSubscription()
								)}
							</TabPane>
						}
						{
							<TabPane tab="Subscription History" key="2">
								{/* {true ? ( */}
								{!isMobile ? (
									<Table
										style={{ padding: 24 }}
										locale={{ emptyText: CustomEmpty }}
										scroll={{ x: "max-content" }}
										dataSource={(userInfo?.subscriptionInfo || [])?.filter(
											(sub) => sub?.status === 0
										)}
										columns={columns}
										className="CompanyTableBox profile"
										pagination={false}
									/>
								) : (
									<Collapse
										accordion
										activeKey={activeKey}
										onChange={handleCollapseChange}
										className="MobileList"
										expandIconPosition="right"
										expandIcon={({ isActive }) => (
											<div className="custom-arrow">
												<img
													src={isActive ? reverseArrow : iconArrow}
													alt="arrow"
												/>
											</div>
										)}
									>
										{(userInfo?.subscriptionInfo || [])
											?.filter((sub) => sub?.status === 0)
											?.map((record) => {
												return (
													<Panel
														header={
															<div className="panel-header">
																{
																	<>
																		{record?.subscribeDate
																			? `${formatDate(
																					new Date(record?.subscribeDate)
																			  )}`
																			: "-"}
																	</>
																}
															</div>
														}
													>
														<Row>
															<Col span={12}>Expired data</Col>
															<Col span={12}>
																{" "}
																<>
																	{record?.expiredDate
																		? `${formatDate(
																				new Date(record?.expiredDate)
																		  )}`
																		: "-"}
																</>
															</Col>
														</Row>

														<Row>
															<Col span={12}>Regions</Col>
															<Col span={12}>
																<>
																	{record?.region
																		?.map((item, index) => {
																			return item ? item : "-";
																		})
																		?.join(", ")}
																</>
															</Col>
														</Row>

														<Row>
															{/* <Row> */}
															{/* <Col span={12}></Col> */}
															{/* <Col span={24} > */}
															<Link
																onClick={() => {
																	onshow(record?.Subscription);
																}}
															>
																View
															</Link>
															{/* </Col> */}
															{/* </Row> */}
														</Row>
													</Panel>
												);
											})}
									</Collapse>
								)}
							</TabPane>
						}
					</Tabs>
					{activeTab != 1 && activeTab != 2 ? customizeRenderEmpty() : ""}
					<Row
						style={{
							paddingTop: 30,
							marginTop: 50,
							marginLeft: 24,
							borderTop: "2px solid #B1C7DC",
						}}
					>
						<Col>
							<a href="mailto:support@nikopartners.com">
								<span className="linkItem" style={{ fontSize: "1.2rem" }}>
									Contact: support@nikopartners.com
								</span>
							</a>
						</Col>
					</Row>
				</div>
			</div>
			<ChangePassword
				editItem={editItem}
				className="common"
				footer={null}
				title={`Change Password`}
				centered
				width={800}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				onOk={(payload) => {
					setVisible(false);
					effect(NLogin, EPostPassWordEdit, payload, () => {
						setregisterVisible(false);
					});
				}}
			/>
			<SubScription
				className="common"
				centered
				subscribe={subscribe}
				width={900}
				visible={show}
				onCancel={() => {
					setShow(false);
				}}
				onOk={() => {
					setShow(false);
				}}
			/>
		</div>
	);
};
