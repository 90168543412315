import React, { useState, useEffect } from "react";
import moment from "moment";
import "./index.less";
import { Row, Col, Typography, Tag, Spin } from "antd";
import { effect, useStore, reducer } from "../../../utils/dva16";
import CustomEmpty from "@/utils/CustomEmpty";
import formatDate from "@/utils/dateFormater";
import "swiper/swiper-bundle.min.css";
import {
	RSetState,
	NTournament,
	EGetDetail,
	MName,
	EGet,
} from "../../../models/contants";
import { Helmet } from "react-helmet";
import imgSource from "./../../../assets/index";
const { Link } = Typography;

export default (props) => {
	const { id } = props.match.params;
	const { history } = props;
	const { tournamentDetailData, loading } = useStore(NTournament);

	useEffect(() => {
		effect(NTournament, EGetDetail, { id });
		return () => {
			reducer(NTournament, RSetState, {
				tournamentDetailData: null,
			});
		};
	}, [id]);

	// Scroll to the top of the page during component rendering
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		effect(MName, EGet, {
			modelName: tournamentDetailData?.tournament,
		});
	}, [tournamentDetailData?.tournament]);
	// ---------------------渲染-------------------------
	const date = new Date(
		tournamentDetailData && tournamentDetailData?.startDate
	);
	const formattedDate = formatDate(date);
	return (
		<div className="CompanyDetail_Box  wd100" style={{ padding: "20px 0" }}>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<Spin spinning={loading} size="large">
				<div className="tournament-container">
					<h1 className="title">{tournamentDetailData?.tournament}</h1>
					<div className="Tag_List_Box">
						{tournamentDetailData?.withGames?.map((vi, iii) => (
							<span
								className="game-title"
								onClick={() => {
									history.push(`/games/details/${vi?.id}`);
								}}
							>
								{vi?.gameEn}
							</span>
						))}
					</div>
					{tournamentDetailData?.announcment_link && (
						<Link
							href={tournamentDetailData?.announcment_link || "#"}
							target="_blank"
							className="website-link"
						>
							<img src={imgSource?.external_link_icon}></img>
							Website
						</Link>
					)}
					<div className="details">
						<div className="left-column">
							<div>
								<span className="item-label">Host Area:</span>
								<span className="item-value">
									{tournamentDetailData?.area?.length > 0
										? tournamentDetailData?.area?.join(", ")
										: "-"}
								</span>
							</div>
							<div>
								<span className="item-label">Region:</span>
								<span className="item-value">
									{tournamentDetailData?.region?.join(", ") || "-"}
								</span>
							</div>
							<div>
								<span className="item-label">Tournament Type:</span>
								<span className="item-value">
									{tournamentDetailData?.tournament_type || "-"}
								</span>
							</div>
							<div>
								<span className="item-label">Scope:</span>
								<span className="item-value">
									{tournamentDetailData?.scope || "-"}
								</span>
							</div>
							<div>
								<span className="item-label">Venue:</span>
								<span className="item-value">
									{tournamentDetailData?.venue || "-"}
								</span>
							</div>
						</div>

						<div className="right-column">
							<div>
								<span className="item-label">Start Date:</span>
								<span className="item-value">
									{tournamentDetailData?.startDate ? formattedDate : "-"}
								</span>
							</div>
							<div>
								<span className="item-label">Prize Pool:</span>
								<span className="item-value">
									{tournamentDetailData?.prizePool
										? "$" +
										  tournamentDetailData?.prizePool
												?.toString()
												?.replace(/(\d)(?=(\d{3})+\b)/g, "$1,")
										: "-"}
								</span>
							</div>
							<div>
								<span className="item-label">Platforms:</span>
								<span className="item-value">
									{tournamentDetailData?.platforms?.length > 0
										? tournamentDetailData?.platforms?.join(", ")
										: "-"}
								</span>
							</div>
							<div>
								<span className="item-label">Characteristics:</span>
								<span className="item-value">
									{tournamentDetailData?.special_characteristics || "-"}
								</span>
							</div>
							<div className="hostsContainer">
								<span className="item-label">Hosts:</span>
								<div className="hosts">
									{tournamentDetailData?.hosts &&
										tournamentDetailData?.hosts?.length > 0 &&
										tournamentDetailData?.hosts?.map((vi, iii) => (
											<span
												className="org-title"
												onClick={() => {
													history.push(`/organizations/details/${vi?.id}`);
												}}
											>
												{vi?.name}
											</span>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Spin>
		</div>
	);
};
