import { Button, Empty } from "antd";
import { saveAs } from 'file-saver';
import { asBlob } from 'html-docx-js-typescript';
import notFound from "@/assets/err/icon_subscription-required@2x.png";
//获取本地存储中的“xf3Token”值，接口请求时需要携带token | Obtain the "xf3Token" value in local storage, which needs to be carried with the token during interface requests
export function getToken() {
	return localStorage.getItem("xf3Token");
}
//将传入的“token”保存到本地存储的“xf3Token”中，以便在接口请求时使用 | Save the incoming 'token' to the locally stored 'xf3Token' for use in interface requests
export function setToken(token) {
	return localStorage.setItem("xf3Token", token);
}

export function customizeRenderEmpty() {
	return (
		<div
			style={{
				display: "flex",

				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh", // Full viewport height
				textAlign: "center",
			}}
		>
			<img
				src={notFound}
				alt="404"
				style={{ maxWidth: "200px", marginBottom: "20px" }}
			/>
			<div style={{ marginLeft: "2rem" }}>
				<h1 style={{ fontWeight: 700, fontSize: "2rem", textAlign: "left" }}>
					Subscription Required
				</h1>
				<p style={{ maxWidth: "25rem", textAlign: "left" }}>
					Your subscription does not include access to this service. Please
					contact{" "}
					<a href="mailto:support@nikopartners.com" className="linkItem">
						support@nikopartners.com
					</a>{" "}
					for more information.
				</p>
			</div>
		</div>
	);
}

export async function toDocx(data, title) {
	const htmlString = `
	Content-Type: text/html; charset="utf-8"
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  p{font-family: "Calibri";}
      </style>
      </head>
      <body>
        ${data}
      </body>
      </html>
	`;
	// 将 HTML 转换为 DOCX | Convert HTML to DOCX
	const docx = await asBlob(htmlString, { orientation: "landscape" });
	// 将 DOCX 下载到本地 | Download DOCX locally
	saveAs(docx, `${title}.docx`);
}
export function htmlEncode(str) {
	var s = "";
	if (str?.length === 0) {
		return "";
	}
	// s = str.replace(/&/g, "&amp;");
	// s = s.replace(/</g, "&lt;");
	// s = s.replace(/>/g, "&gt;");
	// s = s.replace(/ /g, "&nbsp;");
	s = str?.replace(/\’/g, "&#39;"); //IE下不支持实体名称 | Entity names are not supported under IE
	s = s?.replace(/\‘/g, "&#39;"); //IE下不支持实体名称 | Entity names are not supported under IE
	s = s?.replace(/\'/g, "&#39;"); //IE下不支持实体名称 | Entity names are not supported under IE
	// s = s.replace(/\"/g, "&quot;");//将所有的/替换成转义符 | Replace all/with escape characters
	return s;
}


export function noActiveSubscription() {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center",
				margin: "7rem 0",
			}}
		>
			<img
				src={notFound}
				alt="404"
				style={{ maxWidth: "200px", marginBottom: "20px" }}
			/>
			<div style={{ marginLeft: "2rem" }}>
				<h1 style={{ fontWeight: 700, fontSize: "2rem", textAlign: "left" }}>
					No Subscription
				</h1>
				<p style={{ maxWidth: "25rem", textAlign: "left" }}>
					Your account does not have an active subscription. Please contact{" "}
					<a href="mailto:support@nikopartners.com" className="linkItem">
						support@nikopartners.com
					</a>{" "}
					for more information.
				</p>
			</div>
		</div>
	);
}


export const priceSplitter = (number) =>
	(number && number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")) || "0";
