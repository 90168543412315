import {
	NLogin,
	RSetState,
	EPost,
	EGet,
	EPostUser,
	EPostPassWordEdit,
	EPostPassWordReset,
	EPostCompletePassWordReset,
	EGetOne,
	EGetCurrentSubscription,
} from "./contants";
import { requestPost, bindJWTToken, requestGet } from "../utils/dva16";
import { setToken } from "../utils/utils";
import { message } from "antd";

const Login = {
	namespace: NLogin,
	state: {
		userInfo: null,
		collapsed: false,
		authToken: null,
		currentSubscribe: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EPost]({ payload, callback }, { select }) {
			let { token, email, clientUser } = await requestPost("login", payload);
			if (clientUser && token && email) {
				setToken(token);
				bindJWTToken(token);
			}
			callback && callback();
		},
		async [EPostUser](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			await requestPost(`addAccount`, payload);
			callback &&
				callback(
					message.success({
						content: "Registration succeeded, thank you",
						duration: 5,
						className: sc,
					})
				);
		},
		async [EPostPassWordEdit](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			await requestPost(`updatePwd`, payload);
			callback &&
				message.success({
					content: "Password modified successfully",
					duration: 5,
					className: sc,
				});
		},
		async [EPostPassWordReset](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			await requestPost(`resetPassword`, payload);
			callback &&
				message.success({
					content: "The mail has been sent. Please wait",
					duration: 5,
					className: sc,
				});
		},
		async [EPostCompletePassWordReset](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			await requestPost(`completeResetPassword`, payload);
			callback &&
				message.success({
					content: "Password updated successfully!",
					duration: 5,
					className: sc,
				});
		},
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("getUserCenter", payload);
			reducer(RSetState, { userInfo: res });
		},
		async [EGetCurrentSubscription](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet("getCurrentSubscription", payload);
			reducer(RSetState, { currentSubscribe: res });
		},
	},
};
export default Login;
