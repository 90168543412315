import React, { useEffect, useState } from "react";
import moment from "moment";
import "./index.less";
import {
	Button,
	Row,
	Col,
	Typography,
	Tag,
	Empty,
	Spin,
	Collapse,
	Tabs,
} from "antd";
import { effect, useStore, reducer } from "../../../utils/dva16";
import formatDate from "@/utils/dateFormater";
import defaultLogo from "../../../assets/images/game/defaul-logo_game@2x.png";
import CustomEmpty from "@/utils/CustomEmpty";
import {
	EGet,
	EGetOne,
	NInsightsFeed,
	NGame,
	RSetState,
	NExclusives,
	MName,
} from "../../../models/contants";
import { Helmet } from "react-helmet";
import imgSource from "../../../assets";
import { DoubleRightOutlined } from "@ant-design/icons";
const { Link } = Typography;
const { Panel } = Collapse;
const { TabPane } = Tabs;

export default (props) => {
	const { id } = props.match.params;
	const { history } = props;
	const { GameDetail, loading } = useStore(NGame);
	const { EsportsList, InsightsFeedList } = useStore(NInsightsFeed);
	const { exclusivesList } = useStore(NExclusives);
	const [activeTab, setActiveTab] = useState("1");
	const [showAllTags, setShowAllTags] = useState({});

	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const onChange = (key) => {
		setActiveTab(key);
	};

	const customizeRenderEmpty = () => (
		<div style={{ textAlign: "center" }}>
			<Empty
				image={require("@/assets/images/check_you.png")?.default}
				imageStyle={{
					height: 60,
				}}
				description={<span>Please check your personal</span>}
			>
				<Button
					type="primary"
					onClick={() => {
						history.push(`/user/user_profile`);
					}}
				>
					to Personal Center
				</Button>
			</Empty>
		</div>
	);
	useEffect(() => {
		effect(MName, EGet, {
			modelName: GameDetail?.gameEn,
		});
	}, [GameDetail?.gameEn]);
	useEffect(() => {
		effect(NGame, EGetOne, { id });
		effect(NInsightsFeed, EGetOne, {
			type: "Esports",
			game: id,
			page: 1,
			perPage: 5,
		});
		effect(NInsightsFeed, EGet, {
			game: id,
			page: 1,
			perPage: 5,
		});

		return () => {
			reducer(NGame, RSetState, {
				GameDetail: null,
			});
			reducer(NInsightsFeed, RSetState, {
				EsportsList: null,
			});
		};
	}, [id]);
	useEffect(() => {
		//组件渲染时滚动到页面的顶部
		window.scrollTo(0, 0);
	}, []);

	const copyVana = (e, vo) => {
		e.preventDefault();
		if (vo?.content) {
			navigator.clipboard
				.writeText(vo?.content?.replace(/<[^>]+>/g, ""))
				.then(() => {
					console.log("Content copied to clipboard!");
				})
				.catch((err) => {
					console.error("Failed to copy: ", err);
				});
		}
	};

	return (
		<div className="GameDetail_Box wd100" style={{ padding: "20px 0" }}>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<Spin spinning={loading} size="large">
				<div className="CompanyBox mt50">
					<Row className="RowBox mt30 gameCompDet">
						<Col xs={24} lg={12}>
							<div className="Detail_Title_Box">
								<div className="companyDtIco">
									<img
										className="Tabel_Company_Logo"
										src={GameDetail?.logo ? GameDetail.logo : defaultLogo}
										alt={GameDetail?.gameEn}
									/>
								</div>
								<div className="companyDttitle">
									<h1>{GameDetail?.gameEn}</h1>
									<h2>{GameDetail?.gameCn}</h2>
									<div>
										{GameDetail?.withDeveloper?.map((vi, iii) => (
											<span
												className="org-title"
												onClick={() => {
													history.push(`/organizations/details/${vi?.id}`);
												}}
											>
												{vi?.name}
											</span>
										))}
										{GameDetail?.withPublisher?.map((vi, iii) => (
											<span
												className="org-title"
												onClick={() => {
													history.push(`/organizations/details/${vi?.id}`);
												}}
											>
												{vi?.name}
											</span>
										))}
									</div>
									{GameDetail?.website && (
										<p>
											<Link
												href={GameDetail?.website || "#"}
												target="_blank"
												className="website-link"
											>
												<img src={imgSource?.external_link_icon}></img>
												Website
											</Link>
										</p>
									)}
								</div>
							</div>
						</Col>
						<Col xs={24} lg={12}>
							<Tabs onChange={onChange} activeKey={activeTab} size="large">
								<TabPane tab="Game Details" key="1">
									<div className="left-column">
										<div>
											<span className="item-label">Genres:</span>
											<span className="item-value">
												{Array.isArray(GameDetail?.genre) &&
													GameDetail?.genre
														?.map((item, index) => {
															return item;
														})
														?.join(", ")}
											</span>
										</div>
										{GameDetail?.platform &&
											GameDetail?.platform?.length > 0 && (
												<div>
													<span className="item-label">Platforms:</span>
													<span className="item-value">
														{Array.isArray(GameDetail?.platform) &&
															GameDetail?.platform
																?.map((item, index) => {
																	return item;
																})
																?.join(", ")}
													</span>
												</div>
											)}
										<div>
											<span className="item-label">Status:</span>
											<span className="item-value">
												{GameDetail?.status || "-"}
											</span>
										</div>
									</div>
								</TabPane>
								{GameDetail?.withIsbn?.length > 0 && (
									<TabPane tab="ISBN" key="2">
										<div className="left-column">
											{/* <Collapse className="faqAnswer"> */}
											{/* <Panel header={"ISBN"}> */}
											{GameDetail?.withIsbn?.map((i) => {
												const date = new Date(i?.date);
												const formattedDate = formatDate(date);
												return (
													<>
														<div>
															{i?.ISBN && (
																<div>
																	<span className="item-label">
																		ISBN Number:
																	</span>
																	<span className="item-value">{i?.ISBN}</span>
																</div>
															)}
															{formattedDate && (
																<div>
																	<span className="item-label">
																		ISBN Approval Date:
																	</span>
																	<span className="item-value">
																		{formattedDate}
																	</span>
																</div>
															)}
															{i?.lisenceArea && (
																<div>
																	<span className="item-label">
																		China License Type:
																	</span>
																	<span className="item-value">
																		{i?.lisenceArea || "-"}
																	</span>
																</div>
															)}
															{i?.licensedPlatform &&
																i?.licensedPlatform?.length > 0 && (
																	<div>
																		<span className="item-label">
																			Licensed Platform:
																		</span>
																		<span className="item-value">
																			{Array.isArray(i?.licensedPlatform)
																				? i?.licensedPlatform?.join(", ")
																				: i?.licensedPlatform}
																		</span>
																	</div>
																)}
															{i?.licensedStatus && (
																<div>
																	<span className="item-label">
																		Licensed Status:
																	</span>
																	<span className="item-value">
																		{i?.licensedStatus == 1
																			? "Active"
																			: i?.licensedStatus == 0
																			? "Inactive"
																			: ""}
																	</span>
																</div>
															)}
														</div>
														<br />
													</>
												);
											})}
										</div>
									</TabPane>
								)}
							</Tabs>
						</Col>
					</Row>
				</div>
				{/* Insights */}
				<div className="BlogBox mt20" id="insightsSection">
					<div className="RowBox">
						<div className="Global_title">
							<h2>Insights</h2>
						</div>
						<div className="Insights_List">
							<ul className="ProlistBox">
								{InsightsFeedList?.error == 1009 ||
									InsightsFeedList?.total > 0 || <CustomEmpty />}

								{InsightsFeedList?.data && InsightsFeedList?.error != 1009
									? InsightsFeedList?.data?.slice(0, 3)?.map((vo, i) => {
											const date = new Date(vo?.date);
											const formattedDate = formatDate(date);
											const showAll = showAllTags[i] || false;
											return (
												<li key={i}>
													<div className="insight_list_cont">
														<h3>
															<div>
																{/* <span
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: { type: vo?.type },
																		});
																	}}
																>
																	[{vo?.type?.trim()}]
																</span> */}
																<div
																	className="insight_list_title"
																	onClick={() => {
																		history.push({
																			pathname: `/news_articles/vana/details/${vo?.id}`,
																		});
																	}}
																>
																	{vo?.title}
																</div>
															</div>

															<div className="insight_list_date_style">
																{formattedDate}
															</div>
														</h3>
														<div className="areaRegionBox">
															{vo?.area?.length > 0 && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: { area: vo?.area },
																		});
																	}}
																>
																	<img
																		src={imgSource?.map_icon}
																		style={{
																			marginRight: "5px",
																			width: "24px",
																		}}
																	></img>
																	{vo?.area?.length > 0 && vo?.area?.join(", ")}
																</span>
															)}
															{vo?.region?.length > 0 && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: {
																				region: vo?.region,
																			},
																		});
																	}}
																>
																	<img
																		src={imgSource?.globe_gray_icon}
																		style={{
																			marginRight: "5px",
																			width: "24px",
																		}}
																	></img>
																	{vo?.region?.length > 0 &&
																		vo?.region?.join(", ")}
																</span>
															)}
															{vo?.type && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: {
																				type: vo?.type,
																				subType: vo?.subType,
																			},
																		});
																	}}
																>
																	<img
																		src={imgSource?.folder_icon}
																		style={{
																			marginRight: "5px",
																			width: "24px",
																		}}
																	></img>
																	{vo?.type}
																</span>
															)}
														</div>
														<div className="Tag_List_Box">
															{vo?.withGames
																?.slice(0, showAll ? vo?.withGames?.length : 3)
																?.map((vi, iii) => (
																	<span
																		className="game-title"
																		key={iii}
																		onClick={() => {
																			history.push(`/games/details/${vi?.id}`);
																		}}
																	>
																		{vi?.gameEn}
																	</span>
																))}
															{vo?.withCompanys
																?.slice(
																	0,
																	showAll ? vo?.withCompanys?.length : 3
																)
																?.map((vi, ii) => (
																	<span
																		className="org-title"
																		key={ii}
																		onClick={() => {
																			history.push(
																				`/organizations/details/${vi?.id}`
																			);
																		}}
																	>
																		{vi?.name}
																	</span>
																))}
															{(vo?.withCompanys?.length > 3 ||
																vo?.withGames?.length > 3) && (
																<span
																	className="show-all"
																	onClick={() => toggleShowAll(i)}
																>
																	{showAll ? "Show less..." : "Show all..."}
																</span>
															)}
														</div>
														<div
															className="insght_lst_info"
															onClick={() => {
																history.push({
																	pathname: `/news_articles/vana/details/${vo?.id}`,
																});
															}}
														>
															{vo?.content?.replace(/<[^>]+>/g, "")}
														</div>
														<div className="link">
															<a
																href={vo?.link}
																target="_blank"
																onClick={(e) => copyVana(e, vo)}
																style={{ marginRight: "2rem" }}
															>
																<img
																	src={imgSource?.copy_icon}
																	style={{ marginRight: "5px", width: "24px" }}
																></img>
																<span>Copy VANA</span>
															</a>
															{vo?.link && (
																<a href={vo?.link} target="_blank">
																	<img
																		src={imgSource?.external_link_icon}
																		style={{
																			marginRight: "5px",
																			width: "24px",
																		}}
																	></img>
																	<span>Visit Website</span>
																</a>
															)}
														</div>
													</div>
												</li>
											);
									  })
									: customizeRenderEmpty()}
							</ul>
						</div>
						{InsightsFeedList?.data?.length > 3 && (
							<div className="viewWrapper">
								<Button
									className="secReset moreBtn mt20"
									type="default"
									htmlType="button"
									onClick={() => {
										history.push({
											pathname: "/news_articles/VANA",
											state: { game: id, name: GameDetail?.gameEn },
										});
									}}
								>
									View more
									<img src={imgSource?.right_arrow_blue}></img>
								</Button>
							</div>
						)}
					</div>
				</div>
				{/* Niko Knowledge Briefs */}
				<div className="BlogBox mt20" id="exclusiveSection">
					<div className="RowBox">
						<div className="Global_title">
							<h2>Niko Knowledge Briefs</h2>
						</div>
						<div className="Insights_List">
							<ul className="ProlistBox">
								{GameDetail?.exclusives &&
								GameDetail?.exclusives?.length > 0 ? (
									GameDetail?.exclusives?.slice(0, 3)?.map((vo, i) => {
										const date = new Date(vo?.date);
										const formattedDate = formatDate(date);
										const showAll = showAllTags[i] || false;

										return (
											<li key={i}>
												<div className="insight_list_cont">
													<h3>
														<div style={{ display: "block" }}>
															<div
																className="insight_list_title"
																onClick={() => {
																	history.push({
																		pathname: "/niko_knowledge_briefs",
																		state: { game: GameDetail?.id },
																	});
																}}
															>
																{vo?.title}
															</div>
														</div>

														<div className="insight_list_date_style">
															{formattedDate}
														</div>
													</h3>
													<div className="areaRegionBox">
														{vo?.area?.length > 0 && (
															<span
																className="listLink"
																onClick={() => {
																	window.scrollTo({
																		top: 0,
																		behavior: "smooth",
																	});
																	history.push({
																		pathname: "/news_articles/VANA",
																		state: { area: vo?.area },
																	});
																}}
															>
																<img
																	src={imgSource?.map_icon}
																	style={{ marginRight: "5px", width: "24px" }}
																></img>
																{vo?.area?.length > 0 && vo?.area?.join(", ")}
															</span>
														)}
														{vo?.region?.length > 0 && (
															<span
																className="listLink"
																onClick={() => {
																	history.push({
																		pathname: "/news_articles/VANA",
																		state: {
																			region: vo?.region,
																		},
																	});
																}}
															>
																<img
																	src={imgSource?.globe_gray_icon}
																	style={{ marginRight: "5px", width: "24px" }}
																></img>
																{vo?.region?.length > 0 &&
																	vo?.region?.join(", ")}
															</span>
														)}
														{vo?.type && (
															<span
																className="listLink"
																onClick={() => {
																	window.scrollTo({
																		top: 0,
																		behavior: "smooth",
																	});
																	history.push({
																		pathname: "/news_articles/VANA",
																		state: {
																			type: vo?.type,
																			subType: vo?.subType,
																		},
																	});
																}}
															>
																<img
																	src={imgSource?.folder_icon}
																	style={{ marginRight: "5px", width: "24px" }}
																></img>
																{vo?.type}
															</span>
														)}
													</div>
													<div className="Tag_List_Box">
														{vo?.withGames
															?.slice(0, showAll ? vo?.withGames?.length : 3)
															?.map((vi, iii) => (
																<span
																	className="game-title"
																	key={iii}
																	onClick={() => {
																		history.push(`/games/details/${vi?.id}`);
																	}}
																>
																	{vi?.gameEn}
																</span>
															))}
														{vo?.withCompanys
															?.slice(0, showAll ? vo?.withCompanys?.length : 3)
															?.map((vi, ii) => (
																<span
																	className="org-title"
																	key={ii}
																	onClick={() => {
																		history.push(
																			`/organizations/details/${vi?.id}`
																		);
																	}}
																>
																	{vi?.name}
																</span>
															))}
														{(vo?.withCompanys?.length > 3 ||
															vo?.withGames?.length > 3) && (
															<span
																className="show-all"
																onClick={() => toggleShowAll(i)}
															>
																{showAll ? "Show less..." : "Show all..."}
															</span>
														)}
													</div>
													<div
														className="insght_lst_info"
														onClick={() => {
															history.push({
																pathname: "/niko_knowledge_briefs",
																state: { game: GameDetail?.id },
															});
														}}
													>
														{vo?.subHeader?.replace(/<[^>]+>/g, "")}
													</div>
												</div>
											</li>
										);
									})
								) : (
									<CustomEmpty />
								)}
							</ul>
						</div>
						{GameDetail?.exclusives?.length > 3 && (
							<div className="viewWrapper">
								<Button
									className="secReset moreBtn mt20"
									type="default"
									htmlType="button"
									onClick={() => {
										window.scrollTo({ top: 0, behavior: "smooth" });
										history.push({
											pathname: "/niko_knowledge_briefs",
											state: { game: id, name: GameDetail?.gameEn },
										});
									}}
								>
									View more
									<img src={imgSource?.right_arrow_blue}></img>
								</Button>
							</div>
						)}
					</div>
				</div>
				{/* Esport events */}
				<div className="BlogBox mt20" id="esportsSection">
					<div className="RowBox">
						<div className="Global_title">
							<h2>Esports</h2>
						</div>
						<div className="Insights_List">
							<ul className="ProlistBox">
								{EsportsList?.error == 1009 || EsportsList?.total > 0 || (
									<CustomEmpty />
								)}
								{EsportsList?.data && EsportsList?.error != 1009
									? EsportsList?.data?.slice(0, 3)?.map((vo, i) => {
											const date = new Date(vo?.date);
											const formattedDate = formatDate(date);
											const showAll = showAllTags[i] || false;
											return (
												<li key={i}>
													<div className="insight_list_cont">
														<h3>
															<div>
																{/* <span
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: { type: vo?.type },
																		});
																	}}
																>
																	[{vo?.type?.trim()}]
																</span> */}
																<div
																	className="insight_list_title"
																	onClick={() => {
																		history.push({
																			pathname: `/news_articles/vana/details/${vo?.id}`,
																		});
																	}}
																>
																	{vo?.title}
																</div>
															</div>

															<div className="insight_list_date_style">
																{formattedDate}
															</div>
														</h3>
														<div className="areaRegionBox">
															{vo?.area?.length > 0 && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: { area: vo?.area },
																		});
																	}}
																>
																	<img
																		src={imgSource?.map_icon}
																		style={{
																			marginRight: "5px",
																			width: "24px",
																		}}
																	></img>
																	{vo?.area?.length > 0 && vo?.area?.join(", ")}
																</span>
															)}
															{vo?.region?.length > 0 && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: {
																				region: vo?.region,
																			},
																		});
																	}}
																>
																	<img
																		src={imgSource?.globe_gray_icon}
																		style={{
																			marginRight: "5px",
																			width: "24px",
																		}}
																	></img>
																	{vo?.region?.length > 0 &&
																		vo?.region?.join(", ")}
																</span>
															)}
															{vo?.type && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: {
																				type: vo?.type,
																				subType: vo?.subType,
																			},
																		});
																	}}
																>
																	<img
																		src={imgSource?.folder_icon}
																		style={{
																			marginRight: "5px",
																			width: "24px",
																		}}
																	></img>
																	{vo?.type}
																</span>
															)}
														</div>
														<div className="Tag_List_Box">
															{vo?.withGames
																?.slice(0, showAll ? vo?.withGames?.length : 3)
																?.map((vi, iii) => (
																	<span
																		className="game-title"
																		key={iii}
																		onClick={() => {
																			history.push(`/games/details/${vi?.id}`);
																		}}
																	>
																		{vi?.gameEn}
																	</span>
																))}
															{vo?.withCompanys
																?.slice(
																	0,
																	showAll ? vo?.withCompanys?.length : 3
																)
																?.map((vi, ii) => (
																	<span
																		className="org-title"
																		key={ii}
																		onClick={() => {
																			history.push(
																				`/organizations/details/${vi?.id}`
																			);
																		}}
																	>
																		{vi?.name}
																	</span>
																))}
															{(vo?.withCompanys?.length > 3 ||
																vo?.withGames?.length > 3) && (
																<span
																	className="show-all"
																	onClick={() => toggleShowAll(i)}
																>
																	{showAll ? "Show less..." : "Show all..."}
																</span>
															)}
														</div>
														<div
															className="insght_lst_info"
															onClick={() => {
																history.push({
																	pathname: `/news_articles/vana/details/${vo?.id}`,
																});
															}}
														>
															{vo?.content?.replace(/<[^>]+>/g, "")}
														</div>
													</div>
												</li>
											);
									  })
									: customizeRenderEmpty()}
							</ul>
						</div>
						{EsportsList?.data?.length > 3 && (
							<div className="viewWrapper">
								<Button
									className="secReset moreBtn mt20"
									type="default"
									htmlType="button"
									onClick={() => {
										// window.scrollTo({ top: 0, behavior: "smooth" });
										history.push({
											pathname: "/esports",
											state: { game: id, name: GameDetail?.gameEn },
										});
									}}
								>
									View more
									<img src={imgSource?.right_arrow_blue}></img>
								</Button>
							</div>
						)}
					</div>
				</div>
			</Spin>
		</div>
	);
};
