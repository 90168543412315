import React, { useEffect, useState } from "react";
import { NTab, EGet } from "../../../models/contants";
import { effect, useStore } from "../../../utils/dva16";
import MarketModel from "./MarketModel";
import MarketModelTable from "./MarketModelTable";
import Mentain from "../../../utils/mentain.jsx";
import "../../../index.less";
import { Tabs, Empty, Button } from "antd";
const { TabPane } = Tabs;

const MarketTabs = () => {
	const { tabList } = useStore(NTab);
	const [activeTab, setActiveTab] = useState("1");

	useEffect(() => {
		effect(NTab, EGet, { page_name: "market" });
	}, []);

	const onChange = (key) => {
		setActiveTab(key);
	};
	useEffect(() => {
		if ((tabList?.[0]?.bi_tab == 0) & (tabList?.[0]?.table_tab == 1)) {
			setActiveTab("2");
		}
	}, [tabList]);
	const customizeRenderEmpty = () => <Mentain />;
	console.log(tabList);
	return (
		<>
			<Tabs
				onChange={onChange}
				activeKey={activeTab}
				type="card"
				className="custom-tabs"
			>
				{tabList?.[0]?.bi_tab == 1 && (
					<TabPane tab="Dashboard" key="1">
						<MarketModel />
					</TabPane>
				)}
				{tabList?.[0]?.table_tab == 1 && (
					<TabPane tab="Table" key="2">
						<MarketModelTable />
					</TabPane>
				)}
			</Tabs>
			{tabList?.[0]?.bi_tab != 1 && tabList?.[0]?.table_tab != 1
				? customizeRenderEmpty()
				: ""}
		</>
	);
};

export default MarketTabs;
